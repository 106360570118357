import React, { useEffect, useState } from 'react'
import '../styles/tutoriales.css'

function Tutoriales (){


    return(

        <div className="componenteTutorial">
            <h1>TUTORIALES</h1>
         
        </div>


    )

}
export default Tutoriales