import React from 'react';
import EosRequestComponent from '../components/eos/EosRequestComponent';

const EosTest: React.FC = () => {
  return (
    <div>
      <EosRequestComponent />
    </div>
  );
};

export default EosTest;
