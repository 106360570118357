// water-particles.js
// Sistema de simulación de partículas de agua para threejs-mesh-viewer.html

import * as THREE from 'three';

// Variables para la simulación de agua
let scene;
let particleSystem;
let particles;
let particleCount = 5000;
let particlePositions = [];
let particleVelocities = [];
let particleLifetimes = [];
let particleVisible = [];
let simulationActive = false;
let heightField = [];
let heightFieldSize = { width: 0, height: 0 };
let flowSpeed = 1.0;

// Variables para las normales importadas de gen.json
let vertexNormals = [];
let vertexPositions = [];
let useGenJsonNormals = true; // Flag para usar normales de gen.json

// Variables para el sistema de estelas
let trailSystem;
let particleTails = [];
const trailLength = 50; // Aumentado de 5 a 50 (10 veces más largo)

// Colores para las partículas de agua
const PARTICLE_COLORS = [
    new THREE.Color(0x00ffff), // Cian brillante
    new THREE.Color(0x00bfff), // Azul cielo
    new THREE.Color(0x1e90ff), // Azul real
    new THREE.Color(0x00ffff), // Cian
    new THREE.Color(0x80ffff)  // Cian claro
];

// Inicializar el sistema de simulación
export function initWaterSystem(sceneRef) {
    scene = sceneRef;
}

// Extraer normales del JSON
export function extractNormalsFromJSON(data) {
    console.log("Extrayendo normales de gen.json...");
    
    if (!data) {
        console.warn("No hay datos JSON para extraer normales");
        return;
    }
    
    // Guardar posiciones de vértices para referencia
    if (data.vertices && Array.isArray(data.vertices)) {
        vertexPositions = data.vertices.map(v => {
            if (Array.isArray(v) && v.length >= 3) {
                return new THREE.Vector3(v[0], v[1], v[2]);
            }
            return null;
        });
        console.log(`Posiciones de vértices extraídas: ${vertexPositions.length}`);
    }
    
    // Extraer normales si existen
    if (data.normals && Array.isArray(data.normals)) {
        vertexNormals = data.normals.map(n => {
            if (Array.isArray(n) && n.length >= 3) {
                // Normalizar para asegurar que es un vector unitario
                const normal = new THREE.Vector3(n[0], n[1], n[2]);
                normal.normalize();
                return normal;
            }
            return null;
        });
        
        console.log(`Normales extraídas de gen.json: ${vertexNormals.length}`);
        
        // Mostrar algunas normales para depuración
        if (vertexNormals.length > 0) {
            console.log("Ejemplos de normales:");
            for (let i = 0; i < Math.min(5, vertexNormals.length); i++) {
                const n = vertexNormals[i];
                if (n) {
                    console.log(`  [${i}]: (${n.x.toFixed(3)}, ${n.y.toFixed(3)}, ${n.z.toFixed(3)})`);
                } else {
                    console.log(`  [${i}]: null`);
                }
            }
        }
    } else {
        console.warn("No se encontraron normales en gen.json");
        useGenJsonNormals = false;
    }
}

// Función para calcular un gradiente de altura basado en la geometría
export function createHeightField(currentMesh) {
    if (!currentMesh || !currentMesh.current || !currentMesh.current.geometry) {
        console.error("No hay malla para crear el campo de altura");
        return false;
    }
    
    console.log("Creando campo de altura para la simulación...");
    
    // Determinar el tamaño del campo basado en la complejidad de la malla
    const geometry = currentMesh.current.geometry;
    const positions = geometry.attributes.position.array;
    
    // Obtener límites de la malla
    let minX = Infinity, maxX = -Infinity;
    let minY = Infinity, maxY = -Infinity;
    let minZ = Infinity, maxZ = -Infinity;
    
    for (let i = 0; i < positions.length; i += 3) {
        const x = positions[i];
        const y = positions[i + 1];
        const z = positions[i + 2];
        
        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y);
        minZ = Math.min(minZ, z);
        maxZ = Math.max(maxZ, z);
    }
    
    // Determinar resolución (ajustar según complejidad)
    const resolution = 100; // Número de celdas en cada dimensión
    
    // Crear grid vacío
    heightFieldSize = {
        width: resolution,
        height: resolution,
        realWidth: maxX - minX,
        realHeight: maxY - minY,
        minX: minX,
        minY: minY,
        minZ: minZ,
        maxZ: maxZ
    };
    
    // Inicializar array 2D
    heightField = new Array(resolution);
    for (let i = 0; i < resolution; i++) {
        heightField[i] = new Array(resolution).fill(null);
    }
    
    // Proyectar la malla en el grid
    const raycaster = new THREE.Raycaster();
    const direction = new THREE.Vector3(0, 0, -1); // Dirección hacia abajo
    
    for (let i = 0; i < resolution; i++) {
        for (let j = 0; j < resolution; j++) {
            // Calcular posición en el mundo
            const x = minX + (i / (resolution - 1)) * (maxX - minX);
            const y = minY + (j / (resolution - 1)) * (maxY - minY);
            
            // Crear un rayo desde arriba hacia abajo
            const origin = new THREE.Vector3(x, y, maxZ + 10);
            raycaster.set(origin, direction);
            
            // Verificar intersección con la malla
            const intersects = raycaster.intersectObject(currentMesh.current);
            
            if (intersects.length > 0) {
                // Guardar la altura (coordenada Z)
                const hitPoint = intersects[0].point;
                const face = intersects[0].face;
                
                let normal;
                
                // Si estamos usando normales de gen.json y tenemos la intersección
                if (useGenJsonNormals && vertexNormals.length > 0 && face) {
                    // Calcular normal interpolada de los vértices de la cara
                    const a = face.a;
                    const b = face.b;
                    const c = face.c;
                    
                    // Obtener normales de los vértices
                    const normalA = vertexNormals[a] || new THREE.Vector3(0, 0, 1);
                    const normalB = vertexNormals[b] || new THREE.Vector3(0, 0, 1);
                    const normalC = vertexNormals[c] || new THREE.Vector3(0, 0, 1);
                    
                    // Calcular coordenadas baricéntricas para interpolación
                    const baryCoord = calculateBarycentricCoordinates(
                        hitPoint,
                        vertexPositions[a] || new THREE.Vector3(0, 0, 0),
                        vertexPositions[b] || new THREE.Vector3(0, 0, 0),
                        vertexPositions[c] || new THREE.Vector3(0, 0, 0)
                    );
                    
                    // Interpolar normales
                    normal = new THREE.Vector3(0, 0, 0);
                    normal.addScaledVector(normalA, baryCoord.u);
                    normal.addScaledVector(normalB, baryCoord.v);
                    normal.addScaledVector(normalC, baryCoord.w);
                    normal.normalize();
                } else {
                    // Usar normal de la cara si no tenemos normales por vértice
                    normal = face ? face.normal.clone() : new THREE.Vector3(0, 0, 1);
                }
                
                heightField[i][j] = {
                    height: hitPoint.z,
                    normal: normal
                };
            } else {
                // Si no hay intersección, usar un valor predeterminado
                heightField[i][j] = {
                    height: minZ,
                    normal: new THREE.Vector3(0, 0, 1)
                };
            }
        }
        
        // Indicar progreso cada 10%
        if (i % Math.floor(resolution / 10) === 0) {
            console.log(`Creando campo de altura: ${Math.floor((i / resolution) * 100)}%`);
        }
    }
    
    console.log("Campo de altura creado con éxito");
    return true;
}

// Calcular coordenadas baricéntricas para un punto en un triángulo
function calculateBarycentricCoordinates(p, a, b, c) {
    // Vectores del triángulo
    const v0 = new THREE.Vector3().subVectors(c, a);
    const v1 = new THREE.Vector3().subVectors(b, a);
    const v2 = new THREE.Vector3().subVectors(p, a);
    
    // Productos punto
    const d00 = v0.dot(v0);
    const d01 = v0.dot(v1);
    const d11 = v1.dot(v1);
    const d20 = v2.dot(v0);
    const d21 = v2.dot(v1);
    
    // Denominador
    const denom = d00 * d11 - d01 * d01;
    
    // Calcular coordenadas baricéntricas
    let v = (d11 * d20 - d01 * d21) / denom;
    let w = (d00 * d21 - d01 * d20) / denom;
    let u = 1.0 - v - w;
    
    return { u, v, w };
}

// Crear sistema de partículas para simular agua
export function createWaterParticles(currentMesh) {
    // Eliminar sistema de partículas anterior si existe
    if (particleSystem) {
        scene.remove(particleSystem);
        particleSystem.geometry.dispose();
        particleSystem.material.dispose();
        particleSystem = null;
    }
    
    console.log(`Creando sistema de partículas con ${particleCount} partículas...`);
    
    // Crear geometría para las partículas
    const geometry = new THREE.BufferGeometry();
    
    // Crear arrays para posiciones y colores
    const positions = new Float32Array(particleCount * 3);
    const colors = new Float32Array(particleCount * 3);
    const sizes = new Float32Array(particleCount);
    
    // Inicializar partículas con posiciones aleatorias
    particlePositions = [];
    particleVelocities = [];
    particleLifetimes = [];
    particleVisible = [];
    
    // Arrays para almacenar las posiciones anteriores para el efecto de estela
    particleTails = [];
    
    // Asegurarse de que el campo de altura está creado
    if (heightField.length === 0) {
        if (!createHeightField(currentMesh)) {
            console.error("No se pudo crear el campo de altura");
            return false;
        }
    }
    
    const { width, height, minX, minY, realWidth, realHeight, maxZ } = heightFieldSize;
    
    for (let i = 0; i < particleCount; i++) {
        // Posición aleatoria dentro de los límites del terreno
        const gridX = Math.floor(Math.random() * (width - 1));
        const gridY = Math.floor(Math.random() * (height - 1));
        
        // Convertir a coordenadas del mundo
        const x = minX + (gridX / (width - 1)) * realWidth;
        const y = minY + (gridY / (height - 1)) * realHeight;
        
        // Obtener altura del terreno
        let z = maxZ; // Valor por defecto
        if (heightField[gridX] && heightField[gridX][gridY]) {
            z = heightField[gridX][gridY].height + 0.5; // Ligeramente por encima del terreno
        }
        
        // Guardar posición
        const pos = new THREE.Vector3(x, y, z);
        particlePositions.push(pos);
        
        // Inicializar array de posiciones para la estela (trail)
        particleTails.push([]);
        
        // Asignar a la geometría
        positions[i * 3] = pos.x;
        positions[i * 3 + 1] = pos.y;
        positions[i * 3 + 2] = pos.z;
        
        // Velocidad inicial basada en la normal del terreno
        let normal = new THREE.Vector3(0, 0, 1);
        if (heightField[gridX] && heightField[gridX][gridY]) {
            normal = heightField[gridX][gridY].normal || normal;
        }
        
        // Si tenemos normales de vértice disponibles, usarlas aleatoriamente
        if (useGenJsonNormals && vertexNormals.length > 0 && Math.random() < 0.7) {
            // Elegir una normal aleatoria de los vértices cercanos
            const randomIndex = Math.floor(Math.random() * vertexNormals.length);
            const vertexNormal = vertexNormals[randomIndex];
            
            if (vertexNormal) {
                normal = vertexNormal.clone();
                
                // Agregar un poco de variación aleatoria
                normal.x += (Math.random() * 0.1 - 0.05);
                normal.y += (Math.random() * 0.1 - 0.05);
                normal.z += (Math.random() * 0.1 - 0.05);
                normal.normalize();
            }
        }
        
        // Corregir la dirección: agua fluye en la dirección de la pendiente (no en contra)
        // La pendiente está definida por el vector normal, y queremos movernos perpendicular a la normal
        // pero hacia abajo (en la dirección de la pendiente descendente)
        const velocity = new THREE.Vector3(normal.x, normal.y, 0).normalize();
        
        // Añadir un poco de aleatoriedad
        velocity.x += (Math.random() - 0.5) * 0.2;
        velocity.y += (Math.random() - 0.5) * 0.2;
        velocity.normalize().multiplyScalar(0.5 + Math.random() * 0.5); // Velocidad base
        
        particleVelocities.push(velocity);
        
        // Tiempo de vida aleatorio (ciclos de animación)
        particleLifetimes.push(50 + Math.random() * 150);
        
        // Visibilidad inicial aleatoria (mayor probabilidad de ser visible)
        particleVisible.push(Math.random() > 0.3);
        
        // Color aleatorio de la paleta de azules
        const colorIndex = Math.floor(Math.random() * PARTICLE_COLORS.length);
        const color = PARTICLE_COLORS[colorIndex];
        
        colors[i * 3] = color.r;
        colors[i * 3 + 1] = color.g;
        colors[i * 3 + 2] = color.b;
        
        // Tamaño aleatorio - aumentado para mayor visibilidad
        sizes[i] = 4 + Math.random() * 6; // Tamaños entre 4 y 10
    }
    
    // Asignar atributos a la geometría
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
    
    // Crear material para las partículas, usando textura para puntos más suaves
    const texture = createParticleTexture();
    
    const material = new THREE.PointsMaterial({
        size: 5,
        vertexColors: true,
        transparent: true,
        opacity: 0.8,
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        sizeAttenuation: true,
        map: texture
    });
    
    // Crear el sistema de partículas
    particleSystem = new THREE.Points(geometry, material);
    particleSystem.renderOrder = 10; // Asegurarse que se renderiza encima de la malla
    scene.add(particleSystem);
    
    // Crear el sistema de líneas para las estelas
    createTrailSystem();
    
    console.log("Sistema de partículas creado");
    return true;
}

// Crear una textura para partículas más suaves
function createParticleTexture() {
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    
    const context = canvas.getContext('2d');
    
    // Gradiente radial para crear partícula con bordes suaves
    const gradient = context.createRadialGradient(
        32, 32, 0,
        32, 32, 32
    );
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(0.3, 'rgba(255, 255, 255, 0.8)');
    gradient.addColorStop(0.7, 'rgba(255, 255, 255, 0.3)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
    
    context.fillStyle = gradient;
    context.fillRect(0, 0, 64, 64);
    
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    
    return texture;
}

// Crear sistema de líneas para las estelas de las partículas
function createTrailSystem() {
    // Eliminar sistema anterior si existe
    if (trailSystem) {
        scene.remove(trailSystem);
        trailSystem.geometry.dispose();
        trailSystem.material.dispose();
    }
    
    // Crear geometría para las líneas
    const geometry = new THREE.BufferGeometry();
    
    // Máximo número de puntos para todas las estelas
    // Usamos un número más manejable de puntos aunque la estela sea más larga
    const maxTrailPoints = particleCount * 100; // Suficiente para estelas largas con optimización
    
    // Arrays para posiciones y colores
    const positions = new Float32Array(maxTrailPoints * 3);
    const colors = new Float32Array(maxTrailPoints * 3);
    
    // Inicializar arrays con valores por defecto
    for (let i = 0; i < maxTrailPoints; i++) {
        positions[i * 3] = 0;
        positions[i * 3 + 1] = 0;
        positions[i * 3 + 2] = -1000; // Fuera de vista inicialmente
        
        colors[i * 3] = 1;
        colors[i * 3 + 1] = 1;
        colors[i * 3 + 2] = 1;
    }
    
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    
    // Material para las líneas
    const material = new THREE.LineBasicMaterial({
        vertexColors: true,
        transparent: true,
        opacity: 0.6,
        blending: THREE.AdditiveBlending,
        linewidth: 1
    });
    
    // Crear el sistema de líneas
    trailSystem = new THREE.LineSegments(geometry, material);
    trailSystem.frustumCulled = false; // Evitar que se oculten cuando están fuera de vista
    scene.add(trailSystem);
    
    console.log("Sistema de estelas creado");
}

// Actualizar el sistema de estelas
function updateTrails() {
    if (!trailSystem || !particleSystem) return;
    
    const positions = trailSystem.geometry.attributes.position.array;
    const colors = trailSystem.geometry.attributes.color.array;
    
    let pointIndex = 0;
    
    for (let i = 0; i < particleCount; i++) {
        // Solo procesar partículas visibles
        if (!particleVisible[i]) {
            continue;
        }
        
        const positions = trailSystem.geometry.attributes.position.array;
        const currentPos = particlePositions[i].clone();
        const tail = particleTails[i];
        
        // Añadir posición actual al inicio de la cola
        tail.unshift(currentPos.clone());
        
        // Limitar longitud de la cola
        if (tail.length > trailLength) {
            tail.pop();
        }
        
        // Dibujar segmentos de línea para la estela
        // Optimización: no necesitamos dibujar todos los segmentos para estelas largas
        // Usaremos un paso adaptativo para mantener un rendimiento adecuado
        const stepSize = Math.max(1, Math.floor(tail.length / 100)); // Escalar el paso según la longitud
        
        for (let j = 0; j < tail.length - stepSize; j += stepSize) {
            const start = tail[j];
            const end = tail[j + stepSize];
            
            // Índice para este segmento de línea en los arrays de posición y color
            const baseIndex = pointIndex * 3;
            
            // Punto inicial del segmento
            positions[baseIndex] = start.x;
            positions[baseIndex + 1] = start.y;
            positions[baseIndex + 2] = start.z;
            
            // Color del punto inicial (más brillante)
            // Ajustar la opacidad para estelas más largas
            const opacity = Math.pow(1.0 - (j / tail.length), 0.8); // Desvanecimiento más gradual
            colors[baseIndex] = 1 * opacity;
            colors[baseIndex + 1] = 1 * opacity;
            colors[baseIndex + 2] = 1 * opacity;
            
            // Punto final del segmento
            positions[baseIndex + 3] = end.x;
            positions[baseIndex + 4] = end.y;
            positions[baseIndex + 5] = end.z;
            
            // Color del punto final (más tenue)
            const nextOpacity = Math.pow(1.0 - ((j + stepSize) / tail.length), 0.8);
            colors[baseIndex + 3] = 1 * nextOpacity;
            colors[baseIndex + 4] = 1 * nextOpacity;
            colors[baseIndex + 5] = 1 * nextOpacity;
            
            pointIndex += 2;
        }
    }
    
    // Limpiar el resto de los puntos si no se usaron todos
    for (let i = pointIndex; i < particleCount * trailLength * 2; i++) {
        const baseIndex = i * 3;
        positions[baseIndex + 2] = -1000; // Mover fuera de la vista
    }
    
    // Marcar atributos como necesitan actualización
    trailSystem.geometry.attributes.position.needsUpdate = true;
    trailSystem.geometry.attributes.color.needsUpdate = true;
}

// Actualizar las partículas en cada frame
export function updateWaterParticles(currentMesh) {
    if (!particleSystem || !currentMesh.current) return;
    
    const { width, height, minX, minY, realWidth, realHeight, minZ } = heightFieldSize;
    const geometry = particleSystem.geometry;
    const positions = geometry.attributes.position.array;
    
    // Actualizar cada partícula
    for (let i = 0; i < particleCount; i++) {
        // Decrementar tiempo de vida más lentamente para estelas más largas
        particleLifetimes[i] -= 0.5 * flowSpeed; // Reducido para permitir estelas más largas
        
        // Si la partícula ha terminado su ciclo, reiniciarla
        if (particleLifetimes[i] <= 0) {
            resetParticle(i);
            continue;
        }
        
        // Parpadeo: cambiar visibilidad aleatoriamente
        if (Math.random() < 0.01) { // 1% de probabilidad de cambiar por frame
            particleVisible[i] = !particleVisible[i];
        }
        
        // Si no es visible, no actualizar posición
        if (!particleVisible[i]) {
            // Hacer invisible la partícula moviéndola fuera de la vista
            positions[i * 3 + 2] = -1000;
            continue;
        }
        
        // Obtener posición actual
        const pos = particlePositions[i];
        
        // Convertir posición del mundo a índices de la grid
        const gridX = Math.floor(((pos.x - minX) / realWidth) * (width - 1));
        const gridY = Math.floor(((pos.y - minY) / realHeight) * (height - 1));
        
        // Verificar si está dentro de los límites
        if (gridX < 0 || gridX >= width - 1 || gridY < 0 || gridY >= height - 1) {
            resetParticle(i);
            continue;
        }
        
        // Obtener altura del terreno y normal en la posición actual
        let terrainHeight = minZ;
        let normal = new THREE.Vector3(0, 0, 1);
        if (heightField[gridX] && heightField[gridX][gridY]) {
            terrainHeight = heightField[gridX][gridY].height;
            normal = heightField[gridX][gridY].normal || normal;
        }
        
        // Si la partícula está por debajo del terreno, reiniciarla
        if (pos.z < terrainHeight - 0.5) {
            resetParticle(i);
            continue;
        }
        
        // Actualizar velocidad basada en la normal del terreno
        const velocity = particleVelocities[i];
        
        // Añadir componente de gravedad (movimiento hacia abajo)
        velocity.z -= 0.01 * flowSpeed;
        
        // Añadir componente de flujo basado en la normal (agua fluye en dirección de la pendiente)
        // Corrección: en lugar de -normal.x, usamos normal.x para ir en la dirección de la pendiente
        const flowDirection = new THREE.Vector3(normal.x, normal.y, 0).normalize();
        velocity.x = flowDirection.x * 0.5 * flowSpeed + velocity.x * 0.5;
        velocity.y = flowDirection.y * 0.5 * flowSpeed + velocity.y * 0.5;
        
        // Limitar velocidad
        if (velocity.length() > 2 * flowSpeed) {
            velocity.normalize().multiplyScalar(2 * flowSpeed);
        }
        
        // Actualizar posición
        pos.add(velocity);
        
        // Mantener la partícula ligeramente por encima del terreno
        let newTerrainHeight = minZ;
        const newGridX = Math.floor(((pos.x - minX) / realWidth) * (width - 1));
        const newGridY = Math.floor(((pos.y - minY) / realHeight) * (height - 1));
        
        if (newGridX >= 0 && newGridX < width && newGridY >= 0 && newGridY < height &&
            heightField[newGridX] && heightField[newGridX][newGridY]) {
            newTerrainHeight = heightField[newGridX][newGridY].height;
        }
        
        // Mantener la partícula por encima del terreno
        if (pos.z < newTerrainHeight + 0.5) {
            pos.z = newTerrainHeight + 0.5;
        }
        
        // Actualizar la posición en la geometría
        positions[i * 3] = pos.x;
        positions[i * 3 + 1] = pos.y;
        positions[i * 3 + 2] = pos.z;
    }
    
    // Marcar atributos como necesitan actualización
    geometry.attributes.position.needsUpdate = true;
    
    // Actualizar el sistema de estelas
    updateTrails();
}

// Reiniciar una partícula en una nueva posición
function resetParticle(index) {
    const { width, height, minX, minY, realWidth, realHeight, maxZ } = heightFieldSize;
    
    // Posición aleatoria dentro de los límites del terreno
    const gridX = Math.floor(Math.random() * (width - 1));
    const gridY = Math.floor(Math.random() * (height - 1));
    
    // Convertir a coordenadas del mundo
    const x = minX + (gridX / (width - 1)) * realWidth;
    const y = minY + (gridY / (height - 1)) * realHeight;
    
    // Obtener altura del terreno
    let z = maxZ; // Valor por defecto
    if (heightField[gridX] && heightField[gridX][gridY]) {
        z = heightField[gridX][gridY].height + 0.5; // Ligeramente por encima del terreno
    }
    
    // Actualizar posición
    particlePositions[index].set(x, y, z);
    
    // Limpiar la estela
    particleTails[index] = [];
    
    // Actualizar velocidad
    let normal = new THREE.Vector3(0, 0, 1);
    if (heightField[gridX] && heightField[gridX][gridY]) {
        normal = heightField[gridX][gridY].normal || normal;
    }
    
    // Si tenemos normales de vértice disponibles, usarlas aleatoriamente
    if (useGenJsonNormals && vertexNormals.length > 0 && Math.random() < 0.7) {
        // Elegir una normal aleatoria de los vértices cercanos
        const randomIndex = Math.floor(Math.random() * vertexNormals.length);
        const vertexNormal = vertexNormals[randomIndex];
        
        if (vertexNormal) {
            normal = vertexNormal.clone();
            
            // Agregar un poco de variación aleatoria
            normal.x += (Math.random() * 0.1 - 0.05);
            normal.y += (Math.random() * 0.1 - 0.05);
            normal.z += (Math.random() * 0.1 - 0.05);
            normal.normalize();
        }
    }
    
    // La velocidad es la dirección de la normal proyectada en el plano XY
    // Corrección: ahora usamos normal.x en lugar de -normal.x
    const velocity = new THREE.Vector3(normal.x, normal.y, 0).normalize();
    // Añadir un poco de aleatoriedad
    velocity.x += (Math.random() - 0.5) * 0.2;
    velocity.y += (Math.random() - 0.5) * 0.2;
    velocity.normalize().multiplyScalar(0.5 + Math.random() * 0.5);
    
    particleVelocities[index] = velocity;
    
    // Reiniciar tiempo de vida
    particleLifetimes[index] = 50 + Math.random() * 150;
    
    // Visibilidad (mayor probabilidad de ser visible)
    particleVisible[index] = Math.random() > 0.2;
}

// Activar/desactivar la simulación de agua
export function toggleWaterSimulation(currentMesh) {
    simulationActive = !simulationActive;
    
    const btn = document.getElementById('btnSimulacion');
    
    if (simulationActive) {
        btn.textContent = "Detener Simulación";
        btn.classList.add('active');
        
        document.getElementById('loading').textContent = "Iniciando simulación de agua...";
        document.getElementById('loading').style.display = 'block';
        
        // Crear campo de altura si no existe
        if (heightField.length === 0) {
            if (!createHeightField(currentMesh)) {
                simulationActive = false;
                btn.textContent = "Iniciar Simulación";
                btn.classList.remove('active');
                document.getElementById('loading').textContent = "Error: No se pudo crear campo de altura";
                return;
            }
        }
        
        // Crear sistema de partículas si no existe
        if (!particleSystem) {
            if (!createWaterParticles(currentMesh)) {
                simulationActive = false;
                btn.textContent = "Iniciar Simulación";
                btn.classList.remove('active');
                document.getElementById('loading').textContent = "Error: No se pudo crear sistema de partículas";
                return;
            }
        } else {
            // Mostrar sistema existente
            particleSystem.visible = true;
            if (trailSystem) trailSystem.visible = true;
        }
        
        document.getElementById('loading').style.display = 'none';
    } else {
        btn.textContent = "Iniciar Simulación";
        btn.classList.remove('active');
        
        // Ocultar partículas sin eliminarlas
        if (particleSystem) {
            particleSystem.visible = false;
        }
        
        // Ocultar estelas
        if (trailSystem) {
            trailSystem.visible = false;
        }
    }
}

// Actualizar el número de partículas
export function updateParticleCount(count, currentMesh) {
    particleCount = parseInt(count);
    
    // Si la simulación está activa, recrear las partículas
    if (simulationActive && particleSystem) {
        document.getElementById('loading').textContent = `Actualizando a ${particleCount} partículas...`;
        document.getElementById('loading').style.display = 'block';
        
        // Eliminar sistema actual y crear uno nuevo
        if (currentMesh.current){
            createWaterParticles(currentMesh);
        }
        
        document.getElementById('loading').style.display = 'none';
    }
}

// Actualizar la velocidad del flujo
export function updateFlowSpeed(speed) {
    flowSpeed = parseFloat(speed);
    console.log(`Velocidad de flujo actualizada a: ${flowSpeed}`);
}

// Activar/desactivar el uso de normales de vértice
export function toggleNormalsMode() {
    useGenJsonNormals = !useGenJsonNormals;
    
    const btn = document.getElementById('btnNormales');
    
    if (useGenJsonNormals) {
        btn.textContent = "Usar Normales Vértice";
        btn.classList.add('active');
        console.log("Usando normales de vértice de gen.json");
    } else {
        btn.textContent = "Usar Normales Terreno";
        btn.classList.remove('active');
        console.log("Usando normales calculadas del terreno");
    }
    
    // Si la simulación está activa, mostrar un mensaje en pantalla
    if (simulationActive) {
        document.getElementById('loading').textContent = `Modo de normales: ${useGenJsonNormals ? 'Vértice' : 'Terreno'}`;
        document.getElementById('loading').style.display = 'block';
        
        // Ocultar después de 2 segundos
        setTimeout(() => {
            document.getElementById('loading').style.display = 'none';
        }, 2000);
    }
}

// Exportar variables globales para acceso exterior
export { particleCount, particleSystem, trailSystem, simulationActive };
