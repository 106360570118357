import React, { useState } from "react";
import { useAuth } from "../../auth/AuthProvider.tsx";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { Navigate, useNavigate } from "react-router-dom";
import InicioSesiónGoogle from "./inicioSesiónGoogle.tsx";
import "../../styles/inicioSesión.css";

import { API_URL } from "../../auth/constants.ts";
import { AuthResponse, AuthResponseError } from "../../types/types.ts";

import LoginButton from "./LoginButton.tsx";
import SignUpButton from "./SignUpButton.tsx";

import { useAuth0 } from "@auth0/auth0-react";

import Loader from "../usabilidad/Loader.tsx";

function Inicio() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();
  const [errorResponse, setErrorResponse] = useState<string>("");

  const { user, isAuthenticated, isLoading } = useAuth0();

  const goTo = useNavigate();

  // console.log(isAuthenticated, user?.name, user?.email);

  if (isAuthenticated) {
    goTo("/Dashboard");
  }
  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <div className="containerInicio">
        <div className="inicio">
          <h1 className="titleInicio">Inicia sesión con tu cuenta</h1>
          <br />
          <LoginButton />
          <br />
          <br />
          <SignUpButton />
        </div>
      </div>
    </div>
  );
}

export default Inicio;
